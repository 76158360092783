/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type sendAuthSessionMutationVariables = {||};
export type sendAuthSessionMutationResponse = {|
  +sendAuthSession: ?{|
    +my_portal_session: {|
      +id: string,
      +auth_sent: boolean,
      +can_resend_auth: boolean,
      +errors: $ReadOnlyArray<{|
        +attribute: string,
        +error: string,
      |}>,
    |}
  |}
|};
export type sendAuthSessionMutation = {|
  variables: sendAuthSessionMutationVariables,
  response: sendAuthSessionMutationResponse,
|};
*/


/*
mutation sendAuthSessionMutation {
  sendAuthSession {
    my_portal_session {
      id
      auth_sent
      can_resend_auth
      errors {
        attribute
        error
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "SendAuthorizationSessionPayload",
    "kind": "LinkedField",
    "name": "sendAuthSession",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "MyPortal__Session",
        "kind": "LinkedField",
        "name": "my_portal_session",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "auth_sent",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "can_resend_auth",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "CommonModelError",
            "kind": "LinkedField",
            "name": "errors",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "attribute",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "error",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "sendAuthSessionMutation",
    "selections": (v0/*: any*/),
    "type": "MutationRoot",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "sendAuthSessionMutation",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "5faa26b243affaa184e8da4dc92cd4be",
    "id": null,
    "metadata": {},
    "name": "sendAuthSessionMutation",
    "operationKind": "mutation",
    "text": "mutation sendAuthSessionMutation {\n  sendAuthSession {\n    my_portal_session {\n      id\n      auth_sent\n      can_resend_auth\n      errors {\n        attribute\n        error\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '5eaaa1fbf6b694be3a221bcbb7375e8f';

module.exports = node;
