import React, { Suspense } from 'react';
import { RedirectException } from 'found';
import { graphql } from 'react-relay';

import RouterErrorLoading from './RouterErrorLoading';
import RouterLoading from './RouterLoading';


const Sms = React.lazy(() => import(/* webpackChunkName: "subscriptions_sms" */ '../components/subscriptions/Sms.relay'));

const query = graphql`
  query routePropsSubscriptionsSms_Query {
    my_portal_session {
      authorized_sms_notifications
      ...Sms_session
    }
  }
`;

const render = (args) => {
  if(Boolean(args.props)){
    if(!Boolean(args.props.my_portal_session.authorized_sms_notifications)){
      throw new RedirectException("/");
    }
  }
  return (
    <RouterErrorLoading {...args}
      renderSuccess={({props, children}) => (<Suspense fallback={<RouterLoading />}><Sms session={props.my_portal_session}>{children}</Sms></Suspense>) } />
  )
}

export default (args) => {
  return {
    query: query,
    render: render,
  }
}
