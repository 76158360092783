import React, { Suspense } from 'react';
import { RedirectException } from 'found';
import { graphql } from 'react-relay';

import RouterErrorLoading from './RouterErrorLoading';
import RouterLoading from './RouterLoading';
import ErrorScreen from '../../../commonComponents/react/ErrorScreen';

const AuthFormProvider = React.lazy(() => import(/* webpackChunkName: "app_access_root" */ '../components/common/AuthFormProvider.relay'));

//company_subdomain is not required as logout can occur without knowing company_subdomain (session reset)
const query = graphql`
  query routePropsAppAccessVerify_Query{
    access_presenter {
      sign_in_path
      my_portal_session{
        ...AuthFormProvider_session
      }
    }
  }
`;

const render = (args) => {
  if(Boolean(args.props)){
    if(!Boolean(args.props.access_presenter.my_portal_session)){
      throw new RedirectException(args.props.my_portal_session.sign_in_auth_invalid_path);
    }
  }
  return (
    <RouterErrorLoading {...args}
      renderSuccess={({props, children}) => (<Suspense fallback={<RouterLoading />} >
          <AuthFormProvider session={props.access_presenter.my_portal_session} inline />
        </Suspense>
      )}
    />
  )
}

export default () => {
  return {
    query: query,
    render: render
  }
}