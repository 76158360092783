/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type AuthFormProvider_session$ref: FragmentReference;
declare export opaque type AuthFormProvider_session$fragmentType: AuthFormProvider_session$ref;
export type AuthFormProvider_session = {|
  +id: string,
  +auth_sent: boolean,
  +can_resend_auth: boolean,
  +authorization_unauthorized_page_description_label: string,
  +authorization_unauthorized_page_heading: string,
  +authorization_unauthorized_have_not_recieved_an_email: string,
  +authorization_unauthorized_actions_send_again: string,
  +$refType: AuthFormProvider_session$ref,
|};
export type AuthFormProvider_session$data = AuthFormProvider_session;
export type AuthFormProvider_session$key = {
  +$data?: AuthFormProvider_session$data,
  +$fragmentRefs: AuthFormProvider_session$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AuthFormProvider_session",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "auth_sent",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "can_resend_auth",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "authorization_unauthorized_page_description_label",
      "storageKey": null
    },
    {
      "alias": "authorization_unauthorized_page_heading",
      "args": [
        {
          "kind": "Literal",
          "name": "key",
          "value": "myportal.config.authorization.unauthorized.page_heading"
        }
      ],
      "kind": "ScalarField",
      "name": "i18n_config_t",
      "storageKey": "i18n_config_t(key:\"myportal.config.authorization.unauthorized.page_heading\")"
    },
    {
      "alias": "authorization_unauthorized_have_not_recieved_an_email",
      "args": [
        {
          "kind": "Literal",
          "name": "key",
          "value": "myportal.config.authorization.unauthorized.have_not_recieved_an_email"
        }
      ],
      "kind": "ScalarField",
      "name": "i18n_config_t",
      "storageKey": "i18n_config_t(key:\"myportal.config.authorization.unauthorized.have_not_recieved_an_email\")"
    },
    {
      "alias": "authorization_unauthorized_actions_send_again",
      "args": [
        {
          "kind": "Literal",
          "name": "key",
          "value": "myportal.config.authorization.unauthorized.actions.send_again"
        }
      ],
      "kind": "ScalarField",
      "name": "i18n_config_t",
      "storageKey": "i18n_config_t(key:\"myportal.config.authorization.unauthorized.actions.send_again\")"
    }
  ],
  "type": "MyPortal__Session",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = 'd3c77569ec7d7c21f082992abbb1ca24';

module.exports = node;
