import React, { Suspense } from 'react';
import { RedirectException } from 'found';
import { graphql } from 'react-relay';

import RouterErrorLoading from './RouterErrorLoading';
import RouterLoading from './RouterLoading';
import ErrorScreen from '../../../commonComponents/react/ErrorScreen';

const AppAuthMain = React.lazy(() => import(/* webpackChunkName: "app_auth_main" */ '../components/AppAuthMain.relay'));

const query = graphql`
  query routePropsAppAuthMain_Query{
    my_portal_session {
      id
      is_session_ready
      sign_in_auth_invalid_path
      ...MyPortalSessionThemeProvider_session
      ...AppAuthMain_session
    }
  }
`;

const render_authmain = function(props, children){
  return (
    <AppAuthMain session={props.my_portal_session}>
      {children}
    </AppAuthMain>
  )
}

const render = (args) => {
  if(Boolean(args.props) && Boolean(args.props.my_portal_session)){
    if(args.props.my_portal_session.is_session_ready != true){
      throw new RedirectException(args.props.my_portal_session.sign_in_auth_invalid_path);
    }
  }

  return (
    <RouterErrorLoading {...args}
      renderSuccess={({props, children}) => {
        return (<Suspense fallback={<RouterLoading />}>
            { !Boolean(props.my_portal_session) ? (
              <ErrorScreen title="Access Denied" />
            ): ( props.my_portal_session.is_session_ready ? render_authmain(props, children) : render_authForm(props) )}
          </Suspense>
        )
      }}
    />
  )
}

export default () => {
  return {
    query: query,
    render: render
  }
}
