/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type AuthFormProvider_session$ref = any;
export type routePropsAppAccessVerify_QueryVariables = {||};
export type routePropsAppAccessVerify_QueryResponse = {|
  +access_presenter: ?{|
    +sign_in_path: ?string,
    +my_portal_session: ?{|
      +$fragmentRefs: AuthFormProvider_session$ref
    |},
  |}
|};
export type routePropsAppAccessVerify_Query = {|
  variables: routePropsAppAccessVerify_QueryVariables,
  response: routePropsAppAccessVerify_QueryResponse,
|};
*/


/*
query routePropsAppAccessVerify_Query {
  access_presenter {
    sign_in_path
    my_portal_session {
      ...AuthFormProvider_session
      id
    }
  }
}

fragment AuthFormProvider_session on MyPortal__Session {
  id
  auth_sent
  can_resend_auth
  authorization_unauthorized_page_description_label
  authorization_unauthorized_page_heading: i18n_config_t(key: "myportal.config.authorization.unauthorized.page_heading")
  authorization_unauthorized_have_not_recieved_an_email: i18n_config_t(key: "myportal.config.authorization.unauthorized.have_not_recieved_an_email")
  authorization_unauthorized_actions_send_again: i18n_config_t(key: "myportal.config.authorization.unauthorized.actions.send_again")
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sign_in_path",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "routePropsAppAccessVerify_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "MyPortal__AccessPresenter",
        "kind": "LinkedField",
        "name": "access_presenter",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "MyPortal__Session",
            "kind": "LinkedField",
            "name": "my_portal_session",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "AuthFormProvider_session"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "QueryRoot",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "routePropsAppAccessVerify_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "MyPortal__AccessPresenter",
        "kind": "LinkedField",
        "name": "access_presenter",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "MyPortal__Session",
            "kind": "LinkedField",
            "name": "my_portal_session",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "auth_sent",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "can_resend_auth",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "authorization_unauthorized_page_description_label",
                "storageKey": null
              },
              {
                "alias": "authorization_unauthorized_page_heading",
                "args": [
                  {
                    "kind": "Literal",
                    "name": "key",
                    "value": "myportal.config.authorization.unauthorized.page_heading"
                  }
                ],
                "kind": "ScalarField",
                "name": "i18n_config_t",
                "storageKey": "i18n_config_t(key:\"myportal.config.authorization.unauthorized.page_heading\")"
              },
              {
                "alias": "authorization_unauthorized_have_not_recieved_an_email",
                "args": [
                  {
                    "kind": "Literal",
                    "name": "key",
                    "value": "myportal.config.authorization.unauthorized.have_not_recieved_an_email"
                  }
                ],
                "kind": "ScalarField",
                "name": "i18n_config_t",
                "storageKey": "i18n_config_t(key:\"myportal.config.authorization.unauthorized.have_not_recieved_an_email\")"
              },
              {
                "alias": "authorization_unauthorized_actions_send_again",
                "args": [
                  {
                    "kind": "Literal",
                    "name": "key",
                    "value": "myportal.config.authorization.unauthorized.actions.send_again"
                  }
                ],
                "kind": "ScalarField",
                "name": "i18n_config_t",
                "storageKey": "i18n_config_t(key:\"myportal.config.authorization.unauthorized.actions.send_again\")"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "fbc39025824274ad8adbd7604e8cff71",
    "id": null,
    "metadata": {},
    "name": "routePropsAppAccessVerify_Query",
    "operationKind": "query",
    "text": "query routePropsAppAccessVerify_Query {\n  access_presenter {\n    sign_in_path\n    my_portal_session {\n      ...AuthFormProvider_session\n      id\n    }\n  }\n}\n\nfragment AuthFormProvider_session on MyPortal__Session {\n  id\n  auth_sent\n  can_resend_auth\n  authorization_unauthorized_page_description_label\n  authorization_unauthorized_page_heading: i18n_config_t(key: \"myportal.config.authorization.unauthorized.page_heading\")\n  authorization_unauthorized_have_not_recieved_an_email: i18n_config_t(key: \"myportal.config.authorization.unauthorized.have_not_recieved_an_email\")\n  authorization_unauthorized_actions_send_again: i18n_config_t(key: \"myportal.config.authorization.unauthorized.actions.send_again\")\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'ffb6a8b055d4260126cb71cd7cad8728';

module.exports = node;
