import React from 'react';
import ErrorScreen from '../../../commonComponents/react/ErrorScreen';
import RouterLoading from './RouterLoading';
import ErrorBoundary from '../../../commonComponents/react/ErrorBoundary';
import Paper from '@material-ui/core/Paper';

const RouterErrorLoading = (args) => {
  // console.log("RouterErrorLoading", args)
  const { renderSuccess, props, error, retry, resolving, ...rest } = args;
  if(error){
    if(process.env.NODE_ENV=="development"){ console.error("ERROR:", error) }
    let message = "Error"
    let text = null;

    if(error.message){
      message = error.message;
      text = error.text;
    }else if(error[0] && error[0].message){
      message = error[0].message;
    }
    return (
      <ErrorScreen title={message} text={text} />
    )
  }

  if(props){
    return (
      <ErrorBoundary>
        {renderSuccess(args)}
      </ErrorBoundary>
    )
  }else{
    return (
      <RouterLoading />
    )
  }
}

export default RouterErrorLoading