import React, { Suspense } from 'react';
import { Route } from 'found';
import { graphql } from 'react-relay';

import RouterErrorLoading from './RouterErrorLoading';
import RouterLoading from './RouterLoading';

const EmailSubscriptionSettings = React.lazy(() => import(/* webpackChunkName: "subscriptions_email" */ '../components/subscriptions/EmailSubscriptionSettings.relay'));

const query = graphql`
  query routePropsSubscriptionsEmail_Query {
    my_portal_session {
      id
      ...EmailSubscriptionSettings_session
    }
  }
`;

const render = (args) => {
  return (
    <RouterErrorLoading {...args} 
      renderSuccess={({props, children}) => (<Suspense fallback={<RouterLoading />}><EmailSubscriptionSettings session={props.my_portal_session}>{children}</EmailSubscriptionSettings></Suspense>) } />
  )
}

export default (args) => {
  return {
    query: query,
    render: render,
  }
}
