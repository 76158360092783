/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type ApplicationsJobs_session$ref = any;
type MyPortalSessionAuthorizedWrap_session$ref = any;
export type routePropsApplicationsJobs_QueryVariables = {||};
export type routePropsApplicationsJobs_QueryResponse = {|
  +my_portal_session: ?{|
    +id: string,
    +authorized_job_applications: boolean,
    +$fragmentRefs: MyPortalSessionAuthorizedWrap_session$ref & ApplicationsJobs_session$ref,
  |}
|};
export type routePropsApplicationsJobs_Query = {|
  variables: routePropsApplicationsJobs_QueryVariables,
  response: routePropsApplicationsJobs_QueryResponse,
|};
*/


/*
query routePropsApplicationsJobs_Query {
  my_portal_session {
    id
    authorized_job_applications
    ...MyPortalSessionAuthorizedWrap_session
    ...ApplicationsJobs_session
  }
}

fragment ApplicationsJobs_session on MyPortal__Session {
  id
  portal_person {
    id
    job_applications {
      id
      title
      group_id
      status
      status_label
      updated_at
      updated_at_ago
    }
  }
  global_errors_no_records_found: i18n(key: "global.errors.no_records_found")
  applications_jobs_page_heading: i18n_config_t(key: "myportal.config.applications.jobs.page_heading")
  applications_jobs_page_description: i18n_config_t(key: "myportal.config.applications.jobs.page_description")
  applications_jobs_status_label: i18n(key: "myportal.client.applications.jobs.status")
  applications_jobs_date_updated_label: i18n(key: "myportal.client.applications.jobs.date_updated")
  applications_jobs_no_applications_found: i18n_config_t(key: "myportal.config.applications.jobs.no_applications_found")
}

fragment AuthFormProvider_session on MyPortal__Session {
  id
  auth_sent
  can_resend_auth
  authorization_unauthorized_page_description_label
  authorization_unauthorized_page_heading: i18n_config_t(key: "myportal.config.authorization.unauthorized.page_heading")
  authorization_unauthorized_have_not_recieved_an_email: i18n_config_t(key: "myportal.config.authorization.unauthorized.have_not_recieved_an_email")
  authorization_unauthorized_actions_send_again: i18n_config_t(key: "myportal.config.authorization.unauthorized.actions.send_again")
}

fragment MyPortalSessionAuthorizedWrap_session on MyPortal__Session {
  has_user_authorized
  ...AuthFormProvider_session
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "authorized_job_applications",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "routePropsApplicationsJobs_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "MyPortal__Session",
        "kind": "LinkedField",
        "name": "my_portal_session",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "MyPortalSessionAuthorizedWrap_session"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ApplicationsJobs_session"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "QueryRoot",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "routePropsApplicationsJobs_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "MyPortal__Session",
        "kind": "LinkedField",
        "name": "my_portal_session",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "has_user_authorized",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "auth_sent",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "can_resend_auth",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "authorization_unauthorized_page_description_label",
            "storageKey": null
          },
          {
            "alias": "authorization_unauthorized_page_heading",
            "args": [
              {
                "kind": "Literal",
                "name": "key",
                "value": "myportal.config.authorization.unauthorized.page_heading"
              }
            ],
            "kind": "ScalarField",
            "name": "i18n_config_t",
            "storageKey": "i18n_config_t(key:\"myportal.config.authorization.unauthorized.page_heading\")"
          },
          {
            "alias": "authorization_unauthorized_have_not_recieved_an_email",
            "args": [
              {
                "kind": "Literal",
                "name": "key",
                "value": "myportal.config.authorization.unauthorized.have_not_recieved_an_email"
              }
            ],
            "kind": "ScalarField",
            "name": "i18n_config_t",
            "storageKey": "i18n_config_t(key:\"myportal.config.authorization.unauthorized.have_not_recieved_an_email\")"
          },
          {
            "alias": "authorization_unauthorized_actions_send_again",
            "args": [
              {
                "kind": "Literal",
                "name": "key",
                "value": "myportal.config.authorization.unauthorized.actions.send_again"
              }
            ],
            "kind": "ScalarField",
            "name": "i18n_config_t",
            "storageKey": "i18n_config_t(key:\"myportal.config.authorization.unauthorized.actions.send_again\")"
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "MyPortal__PortalPerson",
            "kind": "LinkedField",
            "name": "portal_person",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "MyPortal__JobApplication",
                "kind": "LinkedField",
                "name": "job_applications",
                "plural": true,
                "selections": [
                  (v0/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "title",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "group_id",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "status",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "status_label",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "updated_at",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "updated_at_ago",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": "global_errors_no_records_found",
            "args": [
              {
                "kind": "Literal",
                "name": "key",
                "value": "global.errors.no_records_found"
              }
            ],
            "kind": "ScalarField",
            "name": "i18n",
            "storageKey": "i18n(key:\"global.errors.no_records_found\")"
          },
          {
            "alias": "applications_jobs_page_heading",
            "args": [
              {
                "kind": "Literal",
                "name": "key",
                "value": "myportal.config.applications.jobs.page_heading"
              }
            ],
            "kind": "ScalarField",
            "name": "i18n_config_t",
            "storageKey": "i18n_config_t(key:\"myportal.config.applications.jobs.page_heading\")"
          },
          {
            "alias": "applications_jobs_page_description",
            "args": [
              {
                "kind": "Literal",
                "name": "key",
                "value": "myportal.config.applications.jobs.page_description"
              }
            ],
            "kind": "ScalarField",
            "name": "i18n_config_t",
            "storageKey": "i18n_config_t(key:\"myportal.config.applications.jobs.page_description\")"
          },
          {
            "alias": "applications_jobs_status_label",
            "args": [
              {
                "kind": "Literal",
                "name": "key",
                "value": "myportal.client.applications.jobs.status"
              }
            ],
            "kind": "ScalarField",
            "name": "i18n",
            "storageKey": "i18n(key:\"myportal.client.applications.jobs.status\")"
          },
          {
            "alias": "applications_jobs_date_updated_label",
            "args": [
              {
                "kind": "Literal",
                "name": "key",
                "value": "myportal.client.applications.jobs.date_updated"
              }
            ],
            "kind": "ScalarField",
            "name": "i18n",
            "storageKey": "i18n(key:\"myportal.client.applications.jobs.date_updated\")"
          },
          {
            "alias": "applications_jobs_no_applications_found",
            "args": [
              {
                "kind": "Literal",
                "name": "key",
                "value": "myportal.config.applications.jobs.no_applications_found"
              }
            ],
            "kind": "ScalarField",
            "name": "i18n_config_t",
            "storageKey": "i18n_config_t(key:\"myportal.config.applications.jobs.no_applications_found\")"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "83f19ad922a65a9a802c7ec4fb1558b1",
    "id": null,
    "metadata": {},
    "name": "routePropsApplicationsJobs_Query",
    "operationKind": "query",
    "text": "query routePropsApplicationsJobs_Query {\n  my_portal_session {\n    id\n    authorized_job_applications\n    ...MyPortalSessionAuthorizedWrap_session\n    ...ApplicationsJobs_session\n  }\n}\n\nfragment ApplicationsJobs_session on MyPortal__Session {\n  id\n  portal_person {\n    id\n    job_applications {\n      id\n      title\n      group_id\n      status\n      status_label\n      updated_at\n      updated_at_ago\n    }\n  }\n  global_errors_no_records_found: i18n(key: \"global.errors.no_records_found\")\n  applications_jobs_page_heading: i18n_config_t(key: \"myportal.config.applications.jobs.page_heading\")\n  applications_jobs_page_description: i18n_config_t(key: \"myportal.config.applications.jobs.page_description\")\n  applications_jobs_status_label: i18n(key: \"myportal.client.applications.jobs.status\")\n  applications_jobs_date_updated_label: i18n(key: \"myportal.client.applications.jobs.date_updated\")\n  applications_jobs_no_applications_found: i18n_config_t(key: \"myportal.config.applications.jobs.no_applications_found\")\n}\n\nfragment AuthFormProvider_session on MyPortal__Session {\n  id\n  auth_sent\n  can_resend_auth\n  authorization_unauthorized_page_description_label\n  authorization_unauthorized_page_heading: i18n_config_t(key: \"myportal.config.authorization.unauthorized.page_heading\")\n  authorization_unauthorized_have_not_recieved_an_email: i18n_config_t(key: \"myportal.config.authorization.unauthorized.have_not_recieved_an_email\")\n  authorization_unauthorized_actions_send_again: i18n_config_t(key: \"myportal.config.authorization.unauthorized.actions.send_again\")\n}\n\nfragment MyPortalSessionAuthorizedWrap_session on MyPortal__Session {\n  has_user_authorized\n  ...AuthFormProvider_session\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '33197ecadac27f6c593125f25eff947b';

module.exports = node;
