import {
  Environment,
  Network,
  RecordSource,
  Store,
} from 'relay-runtime';


const csrfToken = document.querySelector('meta[name=csrf-token]').getAttribute('content');

const throwStandardError = function(...args) {
  //console.log(args)
  let {message, status_code, text} = args;
  //console.log(message)
  message = message || "An Error Has Occurred";
  text = text || "Please try again Later.";

  let fullmessage = Boolean(status_code) ? status_code + ": " + message : message;

  throw {message: fullmessage, text: text };
}


const fetchFunction = async (
  request,
  variables,
  cacheConfig,
  uploadables,
) => {

  const headers = {
    'Accept': 'application/json',
    'Content-type': 'application/json',
    'X-CSRF-Token': csrfToken,
  };

  const body = JSON.stringify({
    query: request.text, // GraphQL text from input
    variables,
  });

  const response = await fetch('/graphql/query', {
    method: 'POST',
    headers: headers,
    credentials: 'same-origin',
    body: body
  });

  let json = {};

  try{ // if its a 500 chances are there is a proper json error otherwise parsing may fail
    json = await response.json();
  }catch(e){
    console.error(e);
    throwStandardError({message: "Response could not be parsed!"})
  }
  
  if (json.errors) {
    if(json.errors.length > 0){ //handle access denied error from graphql
      let access_denied_error = json.errors.find((error) => { return error.extensions && error.extensions.code == "ACCESS_DENIED"  })
      if(access_denied_error) { window.location.href = "/signout"; } 
    }
    throw json.errors[0]; //first one for now we need to figure out how to handle multiple later as this is the graphql definition
  }

  if(response.status!=200){ 
    if (json.error) {
      throw json.error;
    }else{ // last resort = english message
      throw {message: "An Error Has Occurred: " + response.status, text: "Please try again later." }
    }
  }

  //console.log("data", data)

  return json;
};

const environment = new Environment({
  network: Network.create(fetchFunction),
  store: new Store(new RecordSource()),  
});

export default environment;