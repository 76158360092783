import React from 'react';
import PropTypes from "prop-types";
import withStyles from '@material-ui/core/styles/withStyles';
import withTheme from '@material-ui/core/styles/withTheme';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = theme => ({
  root: {
    width: '100%',
    height: '100%',
    position: 'relative',
    flex: '1 1 auto'
  },
  fullroot: {
    width: '100%',
    height: '100%'
  },
  paperInline: {
    borderRadius: "50%",
    position: "absolute", 
    top: "50%", 
    left: "50%", 
  },
});

//todo rework thiscomponent

class Loading extends React.Component {
  
  static propTypes = {
    classes: PropTypes.object.isRequired,
    elevation: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    size: PropTypes.number,
    variant: PropTypes.oneOf(['default', 'small', 'large']),
    margin: PropTypes.oneOf(['none', 'dense', 'default']),
  };

  static defaultProps = {
    title: "Loading",
    elevation: 0,
    size: null,
    thickness: 1.7,
    color: "primary",
    variant: "default",
    margin: "default",
  }

  render(){
    const fnVariant = this.variantRender[this.props.variant];
    if(Boolean(fnVariant)){
      return this.variantRender[this.props.variant]();
    }else{
      return this.variantRender["default"]();
    }
    
  }

  getSize = () => {
    const { size, variant } = this.props;
    if(size!=null){ //setting size overrides variant sizing
      return size;
    }

    let dsize = 60;
    switch(variant) {
      case "large":
        dsize = 90;
        break;
      case "small":
        dsize = 30;
        break;
      default: //none
    }
    return dsize;
  }

  variantRender = {
    default: () =>{
      const { classes, theme, margin } = this.props;

      let size = this.getSize();

      let marginsize = 0 
      switch(margin) {
        case "dense":
          marginsize = theme.spacing(0.5)
          break;
        case "default":
          marginsize = theme.spacing(1)
          break;
        default: //none
      }
      let divStyle = {
        minHeight: size,
        margin: marginsize
      }

      return (
        <div className={classes.root}>
          <div style={divStyle} >
            {this.renderLoad()}
          </div>
        </div>
      )
    }
  }

  renderLoad() {
    const { classes, theme, className, title, elevation, thickness } = this.props;

    const progressClassNames = [ className, classes.progressInline ].join(" ");

    let size = this.getSize();

    let paperStyle = {
      width: size,
      height: size,
      marginTop: -(size / 2)
    }
    if(theme.direction=="rtl"){
      paperStyle.marginRight = -(size / 2);
    }else{
      paperStyle.marginLeft = -(size / 2);
    }

    return (
      <div>
        <Paper 
          aria-busy={"true"}
          className={classes.paperInline} 
          style={paperStyle} 
          elevation={elevation || 2} 
        >
          <CircularProgress
            className={progressClassNames} 
            aria-label={title}
            style={{
              width: size,
              height: size
            }} 
            size={size}
            thickness={thickness}
          />
        </Paper>
      </div>
    )
  }
}

export default withTheme(withStyles(styles)(Loading));

