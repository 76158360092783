import React from 'react';
import PropTypes from "prop-types";
import withStyles from '@material-ui/core/styles/withStyles';
import Paper from '@material-ui/core/Paper';

const contentMinMax = {
  minWidth: 350,
  maxWidth: 1200,
}

const styles = theme => ({
  constrainedRoot: {
    display: 'flex',
    flexFlow: 'column',
    flexGrow: 1,
    margin: "0px auto",
    width: "100%",
    minWidth: contentMinMax.minWidth,
    maxWidth: contentMinMax.maxWidth,
  },
  fullRoot: {
    display: 'flex',
    flexFlow: 'column',
    flexGrow: 1,
    width: "100%",
    minWidth: contentMinMax.minWidth,
  },
  cardPaper: {
    marginBottom: theme.spacing(1),
    width: "100%",
    overflow: "visible",
    overflowX: "visible", //only x because some controls do inline absolute popups that cant be clipped vertically
  },
  defaultPadding: {
    padding: theme.spacing(2),
  },
  backgroundRoot: {
    background: theme.palette.background.paper,
  },
  paddForCard: {
    padding: theme.spacing(1)
  }
});


class AppContentStyle extends React.Component {

  static defaultProps = {
    variant: "blank",
    paddContent: false,
    isCard: false,
    transparancy: true,
  }

  static propTypes = {
    classes: PropTypes.object.isRequired,
    variant: PropTypes.string.isRequired,
    paddContent: PropTypes.bool,
    isCard: PropTypes.bool,
    transparancy: PropTypes.bool,
  };

  render() {
    const { classes, variant } = this.props;

    switch(variant) {
      case "constrained":
        return this.renderVariant(classes.constrainedRoot);
      case "full":
        return this.renderVariant(classes.fullRoot);
      default:
        return this.renderBadVariant();
    }
  }

  renderBadVariant() {
    console.error("ApplicationContent: variant not supported: Returning empty div", this.props.variant)
    return (<div>variant not supported</div>)
  }

  renderVariant(className) {
    const { classes, variant, children, paddContent, isCard, transparancy, ...restProps } = this.props;
    let classRoot = [className];
    if(paddContent==true && isCard==false){
      classRoot.push(classes.defaultPadding);
    }
    if(transparancy==false){
      classRoot.push(classes.backgroundRoot);
    }
    if(isCard==true){
      classRoot.push(classes.paddForCard);
    }
    return (
      <div className={classRoot.join(" ")} {...restProps}>
        {this._renderCardOrChildren()}
      </div>
    );
  }

  _renderCardOrChildren() {
    const { classes, children, paddContent, isCard } = this.props;
    if(isCard==true){
      const cardClasses = [classes.cardPaper];
      if(paddContent==true){
        cardClasses.push(classes.defaultPadding);
      }
      return (
        <div>
          <Paper elevation={2} className={cardClasses.join(" ")}>
            {children}
          </Paper>
        </div>
      );
    }else{
      return children;
    }
  }
}

export default withStyles(styles)(AppContentStyle);
