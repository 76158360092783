import React, { Suspense } from 'react';
import { Redirect, Route } from 'found';
import { graphql } from 'react-relay';
import { makeRouteConfig } from 'found';

import routePropsAppAuthMain from './routing/routePropsAppAuthMain.relay';
import routePropsAppAuthContentNavTabs from './routing/routePropsAppAuthContentNavTabs.relay';
import routePropsSubscriptionsEmail from './routing/routePropsSubscriptionsEmail.relay';
import routePropsSubscriptionsSms from './routing/routePropsSubscriptionsSms.relay';
import routePropsApplicationsJobs from './routing/routePropsApplicationsJobs.relay';
import routePropsReferralsCandidates from './routing/routePropsReferralsCandidates.relay';

export default makeRouteConfig(
  <Route
    path="/"
    {...routePropsAppAuthMain()}
  >
    <Route
      path="subscriptions"
      {...routePropsAppAuthContentNavTabs({nav_key: "subscriptions"})}
    >
      <Route
        path="email"
        {...routePropsSubscriptionsEmail()}
      />
      <Route
        path="sms"
        {...routePropsSubscriptionsSms()}
      />
      <Redirect from="/" to="/subscriptions/email" />
    </Route>

    <Route
      path="applications"
      {...routePropsAppAuthContentNavTabs({nav_key: "applications"})}
    >
      <Route
        path="jobs"
        {...routePropsApplicationsJobs()}
      />
      <Redirect from="/" to="/applications/jobs" />
    </Route>

    <Route
      path="referrals"
      {...routePropsAppAuthContentNavTabs({nav_key: "referrals"})}
    >
      <Route
        path="/"
        {...routePropsReferralsCandidates()}
      />
      <Redirect from="" to="/referrals/" />
    </Route>

    <Redirect from="/" to="/subscriptions/email" />
    <Redirect from="" to="/subscriptions/email" />
  </Route>,
);

