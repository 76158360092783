import React, { Suspense } from 'react';
import { Route } from 'found';
import { graphql } from 'react-relay';

import RouterErrorLoading from './RouterErrorLoading';
import RouterLoading from './RouterLoading';
import ErrorScreen from '../../../commonComponents/react/ErrorScreen';


const AppAccessRoot = React.lazy(() => import(/* webpackChunkName: "app_access_root" */ '../components/AppAccessRoot.relay'));

//company_subdomain is not required as logout can occur without knowing company_subdomain (session reset)
const query = graphql`
  query routePropsAppAccessRoot_Query{
    access_presenter {
      is_brand_known
      ...AppAccessRoot_access_presenter
    }
  }
`;

const render = (args) => {
  return (
    <RouterErrorLoading {...args} 
      renderSuccess={({props, children}) => (<Suspense fallback={<RouterLoading />}>
          { !props.access_presenter.is_brand_known ? (
            <ErrorScreen title="Access Denied" />
          ): (
            <AppAccessRoot access_presenter={props.access_presenter} />
          )}
        </Suspense>
      )}
    />
  )
}

export default () => {
  return {
    query: query,
    render: render
  }
}