import React from 'react'
import ReactDOM from 'react-dom'
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { BrowserProtocol, queryMiddleware } from 'farce';
import {
  createFarceRouter,
  createRender,
} from 'found';
import { Resolver } from 'found-relay';
import { ScrollManager } from 'found-scroll';

import ErrorScreen from '../../commonComponents/react/ErrorScreen';

import environment from './environment'

var AppBase = function(elementId, routeConfig, options) {

  const renderError = ({ error }) => {
    return(
      <ErrorScreen title={error.status === 404 ? 'Not found' : 'Error'} text={error.status === 404 ? '' : 'Please Try again later.'} />
    )
  }

  const render = createRender({ renderError });

  if(!Boolean(options)){
    options = {isDarkMode: false};
  }
  const { isDarkMode, primaryColor } = options;
  const dark = Boolean(isDarkMode) && isDarkMode == true
  const primaryMain = Boolean(primaryColor) ? primaryColor : (dark ? "#FFFFFF" : "#000000");
  const theme = createTheme({
    palette: {
      type: dark ? 'dark' : 'light',
      primary: {
        main: primaryMain
      }
    },
  })

  const Router = createFarceRouter({
    historyProtocol: new BrowserProtocol(),
    historyMiddlewares: [queryMiddleware],
    routeConfig: routeConfig,
    render: renderArgs => (
      <ScrollManager renderArgs={renderArgs}>
        { render(renderArgs) }
      </ScrollManager>
    ),
  });

  const renderToElement = document.getElementById('root')

  ReactDOM.render(
    (
      <MuiThemeProvider theme={theme}>
        <Router resolver={new Resolver(environment)} />
      </MuiThemeProvider>
    ),
    renderToElement
  )
}

export default AppBase;