/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type AppAuthContentNavTabs_session$ref = any;
export type routePropsAppAuthContentNavTabs_QueryVariables = {|
  nav_key: string
|};
export type routePropsAppAuthContentNavTabs_QueryResponse = {|
  +my_portal_session: ?{|
    +$fragmentRefs: AppAuthContentNavTabs_session$ref
  |}
|};
export type routePropsAppAuthContentNavTabs_Query = {|
  variables: routePropsAppAuthContentNavTabs_QueryVariables,
  response: routePropsAppAuthContentNavTabs_QueryResponse,
|};
*/


/*
query routePropsAppAuthContentNavTabs_Query(
  $nav_key: String!
) {
  my_portal_session {
    ...AppAuthContentNavTabs_session_NPvA2
    id
  }
}

fragment AppAuthContentNavTabs_session_NPvA2 on MyPortal__Session {
  id
  nav_item: navigation_item_by_key(key: $nav_key) {
    key
    tab_navigation {
      key
      url
      label
      badge_content
      icon {
        ...IconPresenter_icon
      }
    }
  }
}

fragment IconPresenter_icon on CommonIconPresenter {
  type
  name
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "nav_key"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "key",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "routePropsAppAuthContentNavTabs_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "MyPortal__Session",
        "kind": "LinkedField",
        "name": "my_portal_session",
        "plural": false,
        "selections": [
          {
            "args": [
              {
                "kind": "Variable",
                "name": "nav_key",
                "variableName": "nav_key"
              }
            ],
            "kind": "FragmentSpread",
            "name": "AppAuthContentNavTabs_session"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "QueryRoot",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "routePropsAppAuthContentNavTabs_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "MyPortal__Session",
        "kind": "LinkedField",
        "name": "my_portal_session",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": "nav_item",
            "args": [
              {
                "kind": "Variable",
                "name": "key",
                "variableName": "nav_key"
              }
            ],
            "concreteType": "NavigationItem",
            "kind": "LinkedField",
            "name": "navigation_item_by_key",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "NavigationItem",
                "kind": "LinkedField",
                "name": "tab_navigation",
                "plural": true,
                "selections": [
                  (v1/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "url",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "label",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "badge_content",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CommonIconPresenter",
                    "kind": "LinkedField",
                    "name": "icon",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "type",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "58c3b341d79b0321137531ef016464b0",
    "id": null,
    "metadata": {},
    "name": "routePropsAppAuthContentNavTabs_Query",
    "operationKind": "query",
    "text": "query routePropsAppAuthContentNavTabs_Query(\n  $nav_key: String!\n) {\n  my_portal_session {\n    ...AppAuthContentNavTabs_session_NPvA2\n    id\n  }\n}\n\nfragment AppAuthContentNavTabs_session_NPvA2 on MyPortal__Session {\n  id\n  nav_item: navigation_item_by_key(key: $nav_key) {\n    key\n    tab_navigation {\n      key\n      url\n      label\n      badge_content\n      icon {\n        ...IconPresenter_icon\n      }\n    }\n  }\n}\n\nfragment IconPresenter_icon on CommonIconPresenter {\n  type\n  name\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '87b785b31f93423398895dd2e00ccebd';

module.exports = node;
