import React, { Suspense } from 'react';
import { Route } from 'found';
import { graphql } from 'react-relay';

import RouterErrorLoading from './RouterErrorLoading';
import RouterLoading from './RouterLoading';
import ErrorScreen from '../../../commonComponents/react/ErrorScreen';


const AppAccess = React.lazy(() => import(/* webpackChunkName: "app_access" */ '../components/AppAccess.relay'));

//company_subdomain is not required as logout can occur without knowing company_subdomain (session reset)
const query = graphql`
  query routePropsAppAccess_Query{
    access_presenter {
      is_brand_known
      ...AppAccess_access_presenter
    }
  }
`;

const render = (args) => {
  return (
    <RouterErrorLoading {...args} 
      renderSuccess={({props, children}) => (<Suspense fallback={<RouterLoading />}>
          { !props.access_presenter.is_brand_known ? (
            <ErrorScreen title="Access Denied" />
          ): (
            <AppAccess access_presenter={props.access_presenter}>
              {children}
            </AppAccess>
          )}
        </Suspense>
      )}
    />
  )
}

export default () => {
  return {
    query: query,
    render: render
  }
}