import React, { Suspense } from 'react';
import { Route } from 'found';
import { graphql } from 'react-relay';

import RouterErrorLoading from './RouterErrorLoading';
import RouterLoading from './RouterLoading';

const AppAuthContentNavTabs = React.lazy(() => import(/* webpackChunkName: "app_auth_content_nav_tabs" */ '../components/AppAuthContentNavTabs.relay'));


const query = graphql`
  query routePropsAppAuthContentNavTabs_Query($nav_key: String!){
    my_portal_session {
      ...AppAuthContentNavTabs_session @arguments(nav_key: $nav_key)
    }
  }
`;

const render = (args) => {
  return (
    <RouterErrorLoading {...args} 
      renderSuccess={({props, children}) => (<Suspense fallback={<RouterLoading />}><AppAuthContentNavTabs session={props.my_portal_session}>{children}</AppAuthContentNavTabs></Suspense>) } />
  )
}

export default (args) => {
  return {
    query: query,
    render: render,
    prepareVariables: (params, { location }) => {
      return {
        nav_key: args.nav_key
      }
    } 
  }
}