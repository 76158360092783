import React from 'react';
import PropTypes from "prop-types";

import Typography from '@material-ui/core/Typography';

class PageHeadingMain extends React.Component {

  static propTypes = {
    children: PropTypes.string.isRequired,
  };

  render() {
    const { classes, children, ariaLabel, ...restTypography } = this.props;
    return (
      <Typography
        variant="h1"
        color="inherit"
        aria-label={this.props.ariaLabel}
        {...restTypography} >{children}</Typography>
    );
  }
}

export default PageHeadingMain;

