import React, { Suspense } from 'react';
import { Redirect, Route } from 'found';
import { graphql } from 'react-relay';
import { makeRouteConfig } from 'found';

import routePropsAppAccess from './routing/routePropsAppAccess.relay';

import routePropsAppAccessRoot from './routing/routePropsAppAccessRoot.relay';

import routePropsAppAccessVerify from './routing/routePropsAppAccessVerify.relay';

export default makeRouteConfig(
  <Route
    path="/"
    render={(props, children)=>(
      <div>{children}</div>
    )}
  >
    <Route
      path="access"
      {...routePropsAppAccess()}
    >
      <Route
        path=""
        {...routePropsAppAccessRoot()}
      />
      <Route
        path="verify"
        {...routePropsAppAccessVerify()}
      />
    </Route>



    <Redirect from="/" to="/access" />
    <Redirect from="" to="/access" />
  </Route>,
);


