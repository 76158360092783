/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type AppAccessRoot_access_presenter$ref = any;
export type routePropsAppAccessRoot_QueryVariables = {||};
export type routePropsAppAccessRoot_QueryResponse = {|
  +access_presenter: ?{|
    +is_brand_known: boolean,
    +$fragmentRefs: AppAccessRoot_access_presenter$ref,
  |}
|};
export type routePropsAppAccessRoot_Query = {|
  variables: routePropsAppAccessRoot_QueryVariables,
  response: routePropsAppAccessRoot_QueryResponse,
|};
*/


/*
query routePropsAppAccessRoot_Query {
  access_presenter {
    is_brand_known
    ...AppAccessRoot_access_presenter
  }
}

fragment AppAccessRoot_access_presenter on MyPortal__AccessPresenter {
  account_id
  my_portal_session {
    id
    account_id
    errors {
      attribute
      error
    }
  }
  auth_required_path
  label_global_busy_loading: i18n(key: "global.busy.loading")
  label_no_longer_signed_in: i18n(key: "myportal.client.no_longer_signed_in")
  label_global_errors_invalid_auth: i18n(key: "global.errors.invalid_auth")
  label_global_errors_link_expired: i18n(key: "global.errors.link_expired")
  label_close: i18n(key: "global.actions.close")
  label_continue: i18n(key: "global.actions.continue")
  label_page_heading: i18n_config_t(key: "myportal.client.access.page_heading")
  label_page_description: i18n_config_t(key: "myportal.client.access.page_description")
  label_email_address_field_label: i18n(key: "myportal.client.access.email_address_field_label")
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "is_brand_known",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "account_id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "routePropsAppAccessRoot_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "MyPortal__AccessPresenter",
        "kind": "LinkedField",
        "name": "access_presenter",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "AppAccessRoot_access_presenter"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "QueryRoot",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "routePropsAppAccessRoot_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "MyPortal__AccessPresenter",
        "kind": "LinkedField",
        "name": "access_presenter",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "MyPortal__Session",
            "kind": "LinkedField",
            "name": "my_portal_session",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "CommonModelError",
                "kind": "LinkedField",
                "name": "errors",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "attribute",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "error",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "auth_required_path",
            "storageKey": null
          },
          {
            "alias": "label_global_busy_loading",
            "args": [
              {
                "kind": "Literal",
                "name": "key",
                "value": "global.busy.loading"
              }
            ],
            "kind": "ScalarField",
            "name": "i18n",
            "storageKey": "i18n(key:\"global.busy.loading\")"
          },
          {
            "alias": "label_no_longer_signed_in",
            "args": [
              {
                "kind": "Literal",
                "name": "key",
                "value": "myportal.client.no_longer_signed_in"
              }
            ],
            "kind": "ScalarField",
            "name": "i18n",
            "storageKey": "i18n(key:\"myportal.client.no_longer_signed_in\")"
          },
          {
            "alias": "label_global_errors_invalid_auth",
            "args": [
              {
                "kind": "Literal",
                "name": "key",
                "value": "global.errors.invalid_auth"
              }
            ],
            "kind": "ScalarField",
            "name": "i18n",
            "storageKey": "i18n(key:\"global.errors.invalid_auth\")"
          },
          {
            "alias": "label_global_errors_link_expired",
            "args": [
              {
                "kind": "Literal",
                "name": "key",
                "value": "global.errors.link_expired"
              }
            ],
            "kind": "ScalarField",
            "name": "i18n",
            "storageKey": "i18n(key:\"global.errors.link_expired\")"
          },
          {
            "alias": "label_close",
            "args": [
              {
                "kind": "Literal",
                "name": "key",
                "value": "global.actions.close"
              }
            ],
            "kind": "ScalarField",
            "name": "i18n",
            "storageKey": "i18n(key:\"global.actions.close\")"
          },
          {
            "alias": "label_continue",
            "args": [
              {
                "kind": "Literal",
                "name": "key",
                "value": "global.actions.continue"
              }
            ],
            "kind": "ScalarField",
            "name": "i18n",
            "storageKey": "i18n(key:\"global.actions.continue\")"
          },
          {
            "alias": "label_page_heading",
            "args": [
              {
                "kind": "Literal",
                "name": "key",
                "value": "myportal.client.access.page_heading"
              }
            ],
            "kind": "ScalarField",
            "name": "i18n_config_t",
            "storageKey": "i18n_config_t(key:\"myportal.client.access.page_heading\")"
          },
          {
            "alias": "label_page_description",
            "args": [
              {
                "kind": "Literal",
                "name": "key",
                "value": "myportal.client.access.page_description"
              }
            ],
            "kind": "ScalarField",
            "name": "i18n_config_t",
            "storageKey": "i18n_config_t(key:\"myportal.client.access.page_description\")"
          },
          {
            "alias": "label_email_address_field_label",
            "args": [
              {
                "kind": "Literal",
                "name": "key",
                "value": "myportal.client.access.email_address_field_label"
              }
            ],
            "kind": "ScalarField",
            "name": "i18n",
            "storageKey": "i18n(key:\"myportal.client.access.email_address_field_label\")"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e23b1de4f32d9f334128592a7c310c4f",
    "id": null,
    "metadata": {},
    "name": "routePropsAppAccessRoot_Query",
    "operationKind": "query",
    "text": "query routePropsAppAccessRoot_Query {\n  access_presenter {\n    is_brand_known\n    ...AppAccessRoot_access_presenter\n  }\n}\n\nfragment AppAccessRoot_access_presenter on MyPortal__AccessPresenter {\n  account_id\n  my_portal_session {\n    id\n    account_id\n    errors {\n      attribute\n      error\n    }\n  }\n  auth_required_path\n  label_global_busy_loading: i18n(key: \"global.busy.loading\")\n  label_no_longer_signed_in: i18n(key: \"myportal.client.no_longer_signed_in\")\n  label_global_errors_invalid_auth: i18n(key: \"global.errors.invalid_auth\")\n  label_global_errors_link_expired: i18n(key: \"global.errors.link_expired\")\n  label_close: i18n(key: \"global.actions.close\")\n  label_continue: i18n(key: \"global.actions.continue\")\n  label_page_heading: i18n_config_t(key: \"myportal.client.access.page_heading\")\n  label_page_description: i18n_config_t(key: \"myportal.client.access.page_description\")\n  label_email_address_field_label: i18n(key: \"myportal.client.access.email_address_field_label\")\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'c26b9c27d1277c8afb387bf48e052719';

module.exports = node;
