/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type AuthFormProviderQueryVariables = {||};
export type AuthFormProviderQueryResponse = {|
  +my_portal_session: ?{|
    +id: string,
    +can_resend_auth: boolean,
    +authorization_unauthorized_page_description_label: string,
  |}
|};
export type AuthFormProviderQuery = {|
  variables: AuthFormProviderQueryVariables,
  response: AuthFormProviderQueryResponse,
|};
*/


/*
query AuthFormProviderQuery {
  my_portal_session {
    id
    can_resend_auth
    authorization_unauthorized_page_description_label
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "MyPortal__Session",
    "kind": "LinkedField",
    "name": "my_portal_session",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "can_resend_auth",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "authorization_unauthorized_page_description_label",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "AuthFormProviderQuery",
    "selections": (v0/*: any*/),
    "type": "QueryRoot",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "AuthFormProviderQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "c07b3f2abe732d516c5843da29c6f543",
    "id": null,
    "metadata": {},
    "name": "AuthFormProviderQuery",
    "operationKind": "query",
    "text": "query AuthFormProviderQuery {\n  my_portal_session {\n    id\n    can_resend_auth\n    authorization_unauthorized_page_description_label\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '9dd0bbc3476265559928c4bcbf4a2d15';

module.exports = node;
