import React from 'react'
import PropTypes from 'prop-types'
import { withStyles, withTheme } from '@material-ui/core/styles';
import { graphql, createRefetchContainer } from 'react-relay'

import CloseIcon from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import PageHeadingMain from './PageHeadingMain';
import WarningIcon from '@material-ui/icons/Warning';

import SendAuthSessionMutation from '../../mutations/send_auth_session_mutation';

import Loading from '../../../../commonComponents/react/Loading';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const icon_size = 50;

const styles = theme => ({
  root: {
    textAlign: "center"
  },
  iconContainer: {
    width: icon_size,
    marginTop: 0,
    marginBottom: (theme.spacing(1)),
    marginLeft: "auto",
    marginRight: "auto",
    color: theme.palette.talemetry.status.warning.main,
  },
  icon: {
    height: icon_size,
    width: icon_size,
  },
  defaultPadding: {
    marginBottom: theme.spacing(2)
  }
})

class AuthFormProvider extends React.Component {

  static defaultProps = {
    inline: false,
    auth_sent: false,
  }

  static propTypes = {
    inline: PropTypes.bool.isRequired,
  }

  static childContextTypes = {
    authForm: PropTypes.shape({
      showDialog: PropTypes.func,
    })
  }

  state = {
    open: false,
    auth_sent: this.props.session.auth_sent
  }

  getChildContext() {
    return {
      authForm: {
        showDialog: this.displayDialog,
        refetchAuthState: this.refetch,
      }
    }
  }

  componentWillUnmount = () => {
    this.stop_poll()
  }

  componentWillReceiveProps = (nextProps) =>{
    if(this.state.auth_sent!=nextProps.session.auth_sent){
      this.setState({auth_sent: nextProps.session.auth_sent });
    }
  }

  start_poll = () => {
    if(this.state.auth_sent==false){
      SendAuthSessionMutation(this.props.session).then((response) => {
        this.setState({sent_auth: true});
      }).catch((error) => {
        console.log("error", error)
        this.setState({sent_auth: true}); //todo error message
      });
    }else{
      //console.log("Auth Already sent");
    }
    this.refetch(true);
  }

  stop_poll = () => {
    window.clearTimeout(this.stime);
  }

  refetch = (recall=true) => {
    if(!this.finised_state()){
      this.props.relay.refetch(
        null,
        null,
        ()=>{
          if(recall){
            this.stime = window.setTimeout(()=>{
              this.refetch(true);
            }, 20000) //30sec
          }
        },
        { force: true },
      )
    }
  }

  finised_state = () => {
    const {session} = this.props;
    return (session.auth_sent && session.can_resend_auth)
  }

  displayDialog = () => {
    this.setState({open: true}, ()=>{
      this.start_poll()
    });
  }

  handleCloseDialog = () => {
    this.stop_poll();
    this.setState({open: false});

  }

  handleSendAgain = () => {
    this.setState({auth_sent: false}, ()=>{
      this.stop_poll();
      this.start_poll();
    });
  }

  render(){
    const {children, session, inline} = this.props;
    return (
      <React.Fragment>
        { inline==false ? (
          children
        ) : (
          this.renderAuthForm()
        )}
        {this.renderDialog()}
      </React.Fragment>
    )
  }

  componentDidMount(){
    const {inline} = this.props;
    if(inline==true){
      this.start_poll()
    }
  }

  componentWillUnmount(){
    this.stop_poll();
  }

  renderAuthForm(){
    const { classes, session } = this.props;
    return (
      <div className={classes.root}>
        <div className={classes.iconContainer}>
          <WarningIcon color="inherit" className={classes.icon} />
        </div>
        <PageHeadingMain className={classes.defaultPadding}>{session.authorization_unauthorized_page_heading}</PageHeadingMain>
        { this.state.auth_sent ? (
          <React.Fragment>
            <Typography variant="body1" className={classes.defaultPadding}>{session.authorization_unauthorized_page_description_label}</Typography>
            { session.can_resend_auth && (
              <div>
                <Typography variant="h3" className={classes.defaultPadding}>{session.authorization_unauthorized_have_not_recieved_an_email}</Typography>
                <Button onClick={this.handleSendAgain}  color="primary" >{session.authorization_unauthorized_actions_send_again}</Button>
              </div>
            )}
          </React.Fragment>
        ): (
          <Loading />
        )}

      </div>
    )
  }

  renderDialog(){
    return (
      <Dialog
        open={this.state.open}
        TransitionComponent={Transition}
        keepMounted
        onClose={this.handleCloseDialog}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent>
          {this.renderAuthForm()}
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleCloseDialog} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}


export default createRefetchContainer(
  withStyles(styles)(AuthFormProvider),
  {
    session: graphql`
      fragment AuthFormProvider_session on MyPortal__Session {
        id
        auth_sent
        can_resend_auth
        authorization_unauthorized_page_description_label
        authorization_unauthorized_page_heading: i18n_config_t(key: "myportal.config.authorization.unauthorized.page_heading")
        authorization_unauthorized_have_not_recieved_an_email: i18n_config_t(key: "myportal.config.authorization.unauthorized.have_not_recieved_an_email")
        authorization_unauthorized_actions_send_again: i18n_config_t(key: "myportal.config.authorization.unauthorized.actions.send_again")
      }
    `
  },
  graphql`
    query AuthFormProviderQuery {
      my_portal_session {
        id
        can_resend_auth
        authorization_unauthorized_page_description_label
      }
    }
  `
)

