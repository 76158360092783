import React from 'react';
import PropTypes from "prop-types";
import withStyles from '@material-ui/core/styles/withStyles';

import Typography from '@material-ui/core/Typography';
import ErrorIcon from '@material-ui/icons/Error';
import CircularProgress from '@material-ui/core/CircularProgress';


const icon_size = 50;

const styles = theme => ({
  progress: {
    maxWidth: 800, 
    marginTop: (theme.spacing(2)),
    marginBottom: (theme.spacing(2)),
    marginLeft: "auto",
    marginRight: "auto"
  },
  paper: {
    padding: (theme.spacing(2))
  },
  iconContainer: {
    width: icon_size, 
    marginTop: 0,
    marginBottom: (theme.spacing(1)),
    marginLeft: "auto",
    marginRight: "auto"
  },
  icon: {
    height: icon_size, 
    width: icon_size, 
  }
});


class ErrorScreen extends React.Component {

  static defaultProps = {
    title: "Error",
    text: null
  }

  static propTypes = {
    classes: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired,
    text: PropTypes.string,
  };

  render() {
    const { classes, title, text } = this.props;
    return (
      <div className={classes.progress}>
        <div className={classes.iconContainer}>
          <ErrorIcon color="error" className={classes.icon} />
        </div>
        <Typography variant="h5" color="error" align="center">{title}</Typography>
        <Typography variant="body1" align="center">{text}</Typography>
      </div>
    );
  }
}

export default withStyles(styles)(ErrorScreen);

