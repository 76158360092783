/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type AppAccess_access_presenter$ref = any;
export type routePropsAppAccess_QueryVariables = {||};
export type routePropsAppAccess_QueryResponse = {|
  +access_presenter: ?{|
    +is_brand_known: boolean,
    +$fragmentRefs: AppAccess_access_presenter$ref,
  |}
|};
export type routePropsAppAccess_Query = {|
  variables: routePropsAppAccess_QueryVariables,
  response: routePropsAppAccess_QueryResponse,
|};
*/


/*
query routePropsAppAccess_Query {
  access_presenter {
    is_brand_known
    ...AppAccess_access_presenter
  }
}

fragment AppAccessLayout_access_presenter on MyPortal__AccessPresenter {
  is_brand_known
  current_locale
  supported_languages {
    locale
    label
  }
  brand {
    id
    logo_url
    company_name
    privacy_url
  }
  label_privacy_policy_link: i18n_config_t(key: "myportal.config.footer.privacy_policy_link")
  label_no_longer_signed_in: i18n(key: "myportal.client.no_longer_signed_in")
  label_global_errors_invalid_auth: i18n(key: "global.errors.invalid_auth")
  label_global_errors_link_expired: i18n(key: "global.errors.link_expired")
  label_close: i18n(key: "global.actions.close")
  label_continue: i18n(key: "global.actions.continue")
  label_email_address_field_label: i18n(key: "myportal.client.access.email_address_field_label")
}

fragment AppAccess_access_presenter on MyPortal__AccessPresenter {
  brand {
    id
    ...BrandThemeProvider_brand
  }
  ...AppAccessLayout_access_presenter
}

fragment BrandThemeProvider_brand on PublicBrand {
  is_rtl
  font_family
  background_color
  error_color
  secondary_color_text
  secondary_color
  alt_color_text
  alt_color
  primary_color
  is_dark_mode
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "is_brand_known",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "routePropsAppAccess_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "MyPortal__AccessPresenter",
        "kind": "LinkedField",
        "name": "access_presenter",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "AppAccess_access_presenter"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "QueryRoot",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "routePropsAppAccess_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "MyPortal__AccessPresenter",
        "kind": "LinkedField",
        "name": "access_presenter",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "PublicBrand",
            "kind": "LinkedField",
            "name": "brand",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "is_rtl",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "font_family",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "background_color",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "error_color",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "secondary_color_text",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "secondary_color",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "alt_color_text",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "alt_color",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "primary_color",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "is_dark_mode",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "logo_url",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "company_name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "privacy_url",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "current_locale",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "MyPortal__LanguageInfo",
            "kind": "LinkedField",
            "name": "supported_languages",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "locale",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "label",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": "label_privacy_policy_link",
            "args": [
              {
                "kind": "Literal",
                "name": "key",
                "value": "myportal.config.footer.privacy_policy_link"
              }
            ],
            "kind": "ScalarField",
            "name": "i18n_config_t",
            "storageKey": "i18n_config_t(key:\"myportal.config.footer.privacy_policy_link\")"
          },
          {
            "alias": "label_no_longer_signed_in",
            "args": [
              {
                "kind": "Literal",
                "name": "key",
                "value": "myportal.client.no_longer_signed_in"
              }
            ],
            "kind": "ScalarField",
            "name": "i18n",
            "storageKey": "i18n(key:\"myportal.client.no_longer_signed_in\")"
          },
          {
            "alias": "label_global_errors_invalid_auth",
            "args": [
              {
                "kind": "Literal",
                "name": "key",
                "value": "global.errors.invalid_auth"
              }
            ],
            "kind": "ScalarField",
            "name": "i18n",
            "storageKey": "i18n(key:\"global.errors.invalid_auth\")"
          },
          {
            "alias": "label_global_errors_link_expired",
            "args": [
              {
                "kind": "Literal",
                "name": "key",
                "value": "global.errors.link_expired"
              }
            ],
            "kind": "ScalarField",
            "name": "i18n",
            "storageKey": "i18n(key:\"global.errors.link_expired\")"
          },
          {
            "alias": "label_close",
            "args": [
              {
                "kind": "Literal",
                "name": "key",
                "value": "global.actions.close"
              }
            ],
            "kind": "ScalarField",
            "name": "i18n",
            "storageKey": "i18n(key:\"global.actions.close\")"
          },
          {
            "alias": "label_continue",
            "args": [
              {
                "kind": "Literal",
                "name": "key",
                "value": "global.actions.continue"
              }
            ],
            "kind": "ScalarField",
            "name": "i18n",
            "storageKey": "i18n(key:\"global.actions.continue\")"
          },
          {
            "alias": "label_email_address_field_label",
            "args": [
              {
                "kind": "Literal",
                "name": "key",
                "value": "myportal.client.access.email_address_field_label"
              }
            ],
            "kind": "ScalarField",
            "name": "i18n",
            "storageKey": "i18n(key:\"myportal.client.access.email_address_field_label\")"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "406ed4804ea7956a04eacb83326c79d0",
    "id": null,
    "metadata": {},
    "name": "routePropsAppAccess_Query",
    "operationKind": "query",
    "text": "query routePropsAppAccess_Query {\n  access_presenter {\n    is_brand_known\n    ...AppAccess_access_presenter\n  }\n}\n\nfragment AppAccessLayout_access_presenter on MyPortal__AccessPresenter {\n  is_brand_known\n  current_locale\n  supported_languages {\n    locale\n    label\n  }\n  brand {\n    id\n    logo_url\n    company_name\n    privacy_url\n  }\n  label_privacy_policy_link: i18n_config_t(key: \"myportal.config.footer.privacy_policy_link\")\n  label_no_longer_signed_in: i18n(key: \"myportal.client.no_longer_signed_in\")\n  label_global_errors_invalid_auth: i18n(key: \"global.errors.invalid_auth\")\n  label_global_errors_link_expired: i18n(key: \"global.errors.link_expired\")\n  label_close: i18n(key: \"global.actions.close\")\n  label_continue: i18n(key: \"global.actions.continue\")\n  label_email_address_field_label: i18n(key: \"myportal.client.access.email_address_field_label\")\n}\n\nfragment AppAccess_access_presenter on MyPortal__AccessPresenter {\n  brand {\n    id\n    ...BrandThemeProvider_brand\n  }\n  ...AppAccessLayout_access_presenter\n}\n\nfragment BrandThemeProvider_brand on PublicBrand {\n  is_rtl\n  font_family\n  background_color\n  error_color\n  secondary_color_text\n  secondary_color\n  alt_color_text\n  alt_color\n  primary_color\n  is_dark_mode\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '1b88aef3dd6a138bc1f61272f13a1c91';

module.exports = node;
