import React, { Suspense } from 'react';
import { graphql } from 'react-relay';
import { RedirectException } from 'found';

import RouterErrorLoading from './RouterErrorLoading';
import RouterLoading from './RouterLoading';

import MyPortalSessionAuthorizedWrap from '../components/MyPortalSessionAuthorizedWrap.relay';

const ReferralsCandidates = React.lazy(() => import(/* webpackChunkName: "referrals_candidates" */ '../components/referrals/ReferralsCandidates.relay'));

const query = graphql`
  query routePropsReferralsCandidates_Query($date_range: String!) {
    my_portal_session {
      id
      authorized_referrals
      ...MyPortalSessionAuthorizedWrap_session
      ...ReferralsCandidates_session @arguments(date_range: $date_range)
    }
  }
`;

const render = (args) => {
  if(Boolean(args.props)){
    if(!Boolean(args.props.my_portal_session.authorized_referrals)){
      throw new RedirectException("/");
    }
  }
  return (
    <RouterErrorLoading {...args}
      renderSuccess={ ({props, children}) => (
        <Suspense fallback={<RouterLoading />}>
          <MyPortalSessionAuthorizedWrap session={props.my_portal_session}>
            <ReferralsCandidates session={props.my_portal_session}>{children}</ReferralsCandidates>
          </MyPortalSessionAuthorizedWrap>
        </Suspense>)
      } />
  )
}

export default (args) => {
  return {
    query: query,
    render: render,
    prepareVariables: (_params, { context }) => { return { date_range: context && context.date_range ? context.date_range : "current_year"} }
  }
}
