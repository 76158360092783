import {commitMutation, graphql} from 'react-relay';
import environment from '../environment';

const mutation = graphql`
  mutation sendAuthSessionMutation{ 
    sendAuthSession {
      my_portal_session {
        id
        auth_sent
        can_resend_auth
        errors {
          attribute
          error
        }
      }
    }
  }
`;

function getOptimisticResponse(session) {
  return {
    sendAuthSession: {
      my_portal_session: {
        id: session.id,
        auth_sent: true,
        can_resend_auth: false,
        errors: []
      },
    },
  };
}

const sendAuthSession = (session) => new Promise((resolve, reject) => {
  commitMutation(
    environment,
    {
      mutation,
      variables: {
      },
      optimisticResponse: getOptimisticResponse(session),
      onCompleted: (response, errors) => {
        if (errors) return reject(errors);
        if (Boolean(response.sendAuthSession.my_portal_session.errors) && response.sendAuthSession.my_portal_session.errors.length > 0) return reject(response.sendAuthSession.my_portal_session.errors)
        return resolve(response);
      },
      onError: (error) => {
        console.log("error:", error);
        return reject(error);
      }
    },
  );

});

export default sendAuthSession;

