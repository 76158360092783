import React, { Suspense } from 'react';
import { RedirectException } from 'found';
import { graphql } from 'react-relay';

import RouterErrorLoading from './RouterErrorLoading';
import RouterLoading from './RouterLoading';

import MyPortalSessionAuthorizedWrap from '../components/MyPortalSessionAuthorizedWrap.relay';

const ApplicationsJobs = React.lazy(() => import(/* webpackChunkName: "applications_jobs" */ '../components/applications/ApplicationsJobs.relay'));

const query = graphql`
  query routePropsApplicationsJobs_Query {
    my_portal_session {
      id
      authorized_job_applications
      ...MyPortalSessionAuthorizedWrap_session
      ...ApplicationsJobs_session
    }
  }
`;

const render = (args) => {
  if(Boolean(args.props)){
    if(!Boolean(args.props.my_portal_session.authorized_job_applications)){
      throw new RedirectException("/");
    }
  }
  return (
    <RouterErrorLoading {...args}
      renderSuccess={ ({props, children}) => (
        <Suspense fallback={<RouterLoading />}>
          <MyPortalSessionAuthorizedWrap session={props.my_portal_session}>
            <ApplicationsJobs session={props.my_portal_session}>{children}</ApplicationsJobs>
          </MyPortalSessionAuthorizedWrap>
        </Suspense>)
      } />
  )
}

export default (args) => {
  return {
    query: query,
    render: render,
  }
}
