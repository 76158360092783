import React from 'react';
import PropTypes from "prop-types";

import ErrorScreen from "./ErrorScreen";

class ErrorBoundary extends React.Component {

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  state = {
    hasError: false
  }

  componentDidCatch(error, info) {
    //todo log the error
  }

  render() {
    if (this.state.hasError) {
      return (
        <ErrorScreen title="An unknown error occurred" text="Error Boundary: React" />
      );
    }

    return this.props.children;
  }
}
export default ErrorBoundary;