/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type AuthFormProvider_session$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type MyPortalSessionAuthorizedWrap_session$ref: FragmentReference;
declare export opaque type MyPortalSessionAuthorizedWrap_session$fragmentType: MyPortalSessionAuthorizedWrap_session$ref;
export type MyPortalSessionAuthorizedWrap_session = {|
  +has_user_authorized: boolean,
  +$fragmentRefs: AuthFormProvider_session$ref,
  +$refType: MyPortalSessionAuthorizedWrap_session$ref,
|};
export type MyPortalSessionAuthorizedWrap_session$data = MyPortalSessionAuthorizedWrap_session;
export type MyPortalSessionAuthorizedWrap_session$key = {
  +$data?: MyPortalSessionAuthorizedWrap_session$data,
  +$fragmentRefs: MyPortalSessionAuthorizedWrap_session$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MyPortalSessionAuthorizedWrap_session",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "has_user_authorized",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AuthFormProvider_session"
    }
  ],
  "type": "MyPortal__Session",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = '98e1340f5daab3703f7e33211758a5e0';

module.exports = node;
