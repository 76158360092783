import React from 'react';
import PropTypes from 'prop-types';
import {graphql, createFragmentContainer} from 'react-relay'

import AuthFormProvider from './common/AuthFormProvider.relay';

import Typography from '@material-ui/core/Typography';
import AppContentStyle from './AppContentStyle';



class MyPortalSessionAuthorizedWrap extends React.Component {

  static propTypes = {
    session: PropTypes.object.isRequired,
  }

  render() {
    const { session, children } = this.props;
    if(session.has_user_authorized){
      return children;
    } else{
      //console.log("HERE")
    }
    return (
      <AppContentStyle variant="constrained" isCard paddContent>
        <AuthFormProvider session={session} inline />
      </AppContentStyle>
    );
  }
}

export default createFragmentContainer(
  MyPortalSessionAuthorizedWrap,
  {
    session: graphql`
      fragment MyPortalSessionAuthorizedWrap_session on MyPortal__Session {
        has_user_authorized
        ...AuthFormProvider_session
      }
    `
  }
)
